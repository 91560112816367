import { handleActions } from 'redux-actions';
import * as types from './constants';

export let ConversationState = {
    isLoading: false,
    conversations: []
};

export default handleActions(
    {
        [types.CREATE_CONVERSATION]: (state = ConversationState, action) => {
            let convoList = state.conversations;
            convoList.push(action.payload);

            state = {
                isLoading: state.isLoading,
                conversations: convoList
            };

            return state;
        },

        [types.LOAD_CONVERSATIONS]: (state = ConversationState, action) => {
            state = {
                isLoading: state.isLoading,
                conversations: [...action.payload]
            };
            return state;
        },

        [types.ADD_MESSAGE]: (state = ConversationState, action) => {
            let message = action.payload;
            let convoList = state.conversations;

            convoList.forEach((n) => {
                if (n.id === parseInt(message.conversation_id)) {
                    n.messages.push(message);
                }
            });

            state = {
                isLoading: state.isLoading,
                conversations: convoList
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = ConversationState, action) => {
            state = {
                isLoading: action.payload,
                conversations: state.conversations
            };

            return state;
        }
    },
    ConversationState
);
