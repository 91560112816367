import * as types from './constants.js';
import { actions } from '../index';
import { PostRequest, GetRequest } from '../../../Api';

export const CreateAlbum = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_ALBUM,
            payload: data
        });
    };
};

export const AddMusic = (form, token) => {
    return (dispatch) => {
        
        dispatch(Loading(true));

        const result = PostRequest(form, 'music', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.ADD_MUSIC,
                    payload: data
                });
                dispatch(Loading(false));
                dispatch(actions.notification.SetMessage('Music added to album!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const GetAlbums = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'albums');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_ALBUMS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const GetAlbum = (token, id) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'albums/' + id);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_ALBUM,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
