import React, { useState } from 'react';
import '../../../Styling/auth-layout-form.scss';
import './Style/index.scss';
import { useDispatch } from 'react-redux';
import { FormBuilder, Token, PostRequest, actions } from '../../../';

export function UploadModal(props) {
    const dispatch = useDispatch();

    const [uploadProgress, setUploadProgress] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const { title, closeModal, editResource, id, setData, type, fields, user, eventId, reload = () => {} } =
        props;

    const progressObserver = (event) => {
        const { loaded, total } = event;

        const percent = Math.floor((loaded * 100) / total);

        setUploadProgress(percent);
    };

    const updateStore = (data) => {
        switch (type) {
            case 'playlist':
                if (editResource) {
                    dispatch(actions.playlist.GetPlaylist(Token));
                    break;
                }
                dispatch(actions.playlist.CreateMusic(data));
                break;
            case 'music':
                if (data.hasOwnProperty('album_id')) {
                    setData(data);
                }
                break;
            case 'artist':
                if (editResource) {
                    dispatch(actions.artists.GetArtists(Token));
                    break;
                }
                dispatch(actions.artists.CreateArtist(data));
                break;
            case 'videos':
                if (editResource) {
                    dispatch(actions.videos.GetVideos(Token));
                    break;
                }
                dispatch(actions.videos.CreateVideo(data));
                break;
            case 'movies':
                dispatch(actions.movies.CreateMovie(data));
                break;
            case 'mix':
                if (editResource) {
                    dispatch(actions.mix.GetMix(Token));
                    break;
                }
                dispatch(actions.mix.CreateMix(data));
                break;
            case 'beatz':
                if (editResource) {
                    dispatch(actions.beatz.GetBeat(Token));
                    break;
                }
                dispatch(actions.beatz.CreateBeat(data));
                break;
            case 'albums':
                if (editResource) {
                    dispatch(actions.albums.GetAlbums(Token));
                    break;
                }
                dispatch(actions.albums.CreateAlbum(data));
                break;
            default:
                break;
        }
    };

    const uploadResource = (e) => {
        e.preventDefault();

        // just in case we want to change the value of type, we v to consider how its being used here
        const url = editResource ? `${type}/${id}` : type;

        // I had to send the request this way bcus i needed the upload file progress on this component
        // there might be a way to add it to the redux store
        const response = PostRequest(e.target.elements, url, Token, progressObserver, editResource);

        setIsLoading(true);

        response
            .then(({ data }) => {
                setIsLoading(false);
                updateStore(data);
            })
            .catch((error) => {
                // console.log(error);
            }).finally(() => {
                setUploadProgress(0);
                closeModal();
                reload();
            });
    };

    return (
        <div className="video-upload-modal">
            <div className="custom-form">
                <h1>{title}</h1>
                <img
                    onClick={closeModal}
                    className="close-modal-icon"
                    src={require('./Images/close-modal.png').default}
                    alt="close"
                />

                <form onSubmit={uploadResource}>
                    {uploadProgress !== 0 && (
                        <div className="progress-bar">
                            <div
                                className="progress-loader"
                                style={{ width: `${uploadProgress}%` }}>
                                <span>{uploadProgress}%</span>
                            </div>
                        </div>
                    )}

                    {editResource && <input type="hidden" name="_method" value="PUT" />}

                    {eventId && <input type="hidden" name="event_id" value={eventId} />}

                    <FormBuilder
                        type={type}
                        FormFields={fields}
                        editResource={editResource}
                        id={id}
                        user={user}
                        isLoading={isLoading}
                    />
                </form>
            </div>
        </div>
    );
}
