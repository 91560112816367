import { App, AppState } from './App';
import { User, UserState } from './User';
import { Profile, ProfileState } from './Profile';
import { Post, PostState } from './Posts';
import { Playlist, PlaylistState } from './Playlist';
import { Error, ErrorState } from './Error';
import { Comments, CommentState } from './Comments';
import { Movie, MovieState } from './Movies';
import { Video, VideoState } from './Videos';
import { Event, EventState } from './Events';
import { Follow, FollowState } from './Followers';
import { News, NewsState } from './News';
import { Album, AlbumState } from './Album';
import { Artist, ArtistState } from './Artist';
import { Mix, MixState } from './Mix';
import { Conversations, ConversationState } from './Conversation';
import { Notification, NotificationState } from './Notification';
import { UserSearchResults, UserSearchResultState } from './SearchUserResult';
import { Beatz, BeatzState } from './Beatz';

export const reducers = {
    user: User.reducers,
    artists: Artist.reducers,
    follow: Follow.reducers,
    profile: Profile.reducers,
    app: App.reducers,
    posts: Post.reducers,
    playlist: Playlist.reducers,
    error: Error.reducers,
    comments: Comments.reducers,
    movies: Movie.reducers,
    videos: Video.reducers,
    events: Event.reducers,
    news: News.reducers,
    albums: Album.reducers,
    mix: Mix.reducers,
    beatz: Beatz.reducers,
    notification: Notification.reducers,
    conversations: Conversations.reducers,
    userSearchResults: UserSearchResults.reducers
};

export const actions = {
    user: User.actions,
    artists: Artist.actions,
    follow: Follow.actions,
    profile: Profile.actions,
    app: App.actions,
    posts: Post.actions,
    playlist: Playlist.actions,
    error: Error.actions,
    comments: Comments.actions,
    movies: Movie.actions,
    videos: Video.actions,
    events: Event.actions,
    news: News.actions,
    albums: Album.actions,
    mix: Mix.actions,
    beatz: Beatz.actions,
    notification: Notification.actions,
    conversations: Conversations.actions,
    userSearchResults: UserSearchResults.actions
};

export const states = {
    user: UserState,
    artists: ArtistState,
    follow: FollowState,
    profile: ProfileState,
    app: AppState,
    posts: PostState,
    playlist: PlaylistState,
    error: ErrorState,
    comments: CommentState,
    movies: MovieState,
    videos: VideoState,
    events: EventState,
    news: NewsState,
    albums: AlbumState,
    mix: MixState,
    beatz: BeatzState,
    notification: NotificationState,
    conversations: ConversationState,
    userSearchResults: UserSearchResultState
};
