import { handleActions } from 'redux-actions';
import * as types from './constants';

export let UserSearchResultState = {
    isLoading: false,
    result: []
};

export default handleActions(
    {
        [types.SEARCH]: (state = UserSearchResultState, action) => {
            state.result = action.payload;

            return state;
        },

        [types.LOADING_STATUS]: (state = UserSearchResultState, action) => {
            state.isLoading = action.payload;

            return state;
        }
    },
    UserSearchResultState
);
