import { handleActions } from 'redux-actions';
import * as types from './constants';

export let CommentState = {
    isLoading: false,
    comments: []
};

export default handleActions(
    {
        [types.ADD_COMMENT]: (state = CommentState, action) => {
            let commentList = state.comments;
            console.log(commentList)
            commentList.push(action.payload);

            state = {
                isLoading: state.isLoading,
                comments: commentList
            };

            return state;
        },

        [types.LOAD_COMMENTS]: (state = CommentState, action) => {
            state = {
                isLoading: state.isLoading,
                comments: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = CommentState, action) => {
            state = {
                isLoading: action.payload,
                comments: state.comments
            };
            return state;
        }
    },
    CommentState
);
