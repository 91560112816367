import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Style/index.scss';

export function MobileNavigator(props) {
    const history = useHistory();

    const { modalRef, title } = props;

    const back = () => {
        history.goBack();
    };

    return (
        <div className="mobile-navigator">
            <Link to={'#'} onClick={back} className="back-link">
                <img src={require('./Images/back.png').default} alt="back" />
            </Link>

            <h4>{title}</h4>

            <div className="nav-actions">
                {modalRef && (
                    <img
                        src={require('./Images/upload.png').default}
                        alt="upload"
                        onClick={() => modalRef.current.open()}
                    />
                )}
            </div>
        </div>
    );
}
