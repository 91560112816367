import React, { useState } from 'react';
import { Comment, PostActions, CommentForm, url, Token, actions } from '../../../';
import ReactPlayer from 'react-player/lazy';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import './Style/index.scss';
import { postSettingOptions } from '../PostSettingsOptions';

export function ViewResource(props) {
    const dispatch = useDispatch();

    const { closeModal, type, post, mediaType } = props;

    const user = post ? post.user : {};

    const comments = post ? post.comments : [];

    const [showSetting, setShowSetting] = useState(false);

    const toggleSettingCard = () => {
        setShowSetting(!showSetting);
    };

    const deleteResource = () => {
        if (window.confirm('Are you sure you want to delete?')) {
            switch (type) {
                case 'post':
                    dispatch(actions.posts.DeletePost(post.id, Token));
                    break;
                case 'videos':
                    dispatch(actions.videos.DeleteVideo(post.id, Token));
                    break;
                case 'movies':
                    dispatch(actions.movies.DeleteMovie(post.id, Token));
                    break;
                default:
                    break;
            }
        }

        closeModal();
    };

    const editResource = () => {
        if (window.confirm('Are you sure you want to edit?')) {
            switch (type) {
                case 'post':
                    // dispatch(actions.posts.DeletePost(post.id, Token));
                    break;
                case 'videos':
                    console.log('editting');
                    // dispatch(actions.videos.DeleteVideo(post.id, Token));
                    break;
                case 'movies':
                    // dispatch(actions.movies.DeleteMovie(post.id, Token));
                    break;
                default:
                    break;
            }
        }

        closeModal();
    };

    const hadlePostAction = (action) => {
        switch (action) {
            case 'delete':
                deleteResource();
                break;

            case 'edit':
                editResource();
                break;

            default:
                break;
        }
    };

    const avatar =
        user && user.image
            ? `${url}/storage/avatars/${user.image}`
            : require('../../../Images/avatar.png').default;

    return post ? (
        <div className={'view-resource-card'}>
            {/* View resource */}
            <div className={'resource'}>
                {/* Africa plus logo */}
                <img
                    src={require('../../../Images/Logo.png').default}
                    className="logo"
                    alt="logo"
                />

                {/* Close modal icon */}
                <img
                    src={require('./Images/close-modal.png').default}
                    className="close-modal"
                    onClick={closeModal}
                    alt="close modal"
                />

                {type === 'videos' || type === 'movies' ? (
                    <div className="video-player">
                        <ReactPlayer
                            url={`${url}/storage/${mediaType}/${post.path}`}
                            controls={true}
                            width="100%"
                            height="100%"
                            onPlay={() => {
                                console.log('playing');
                            }}
                        />
                    </div>
                ) : (
                    <div
                        className={'media'}
                        style={{
                            backgroundImage: `url(${url}/storage/posts/${post.image})`
                        }}
                    >
                        {' '}
                    </div>
                )}
            </div>

            {/* Resource commments */}
            <div className={'resource-details'}>
                {/* Uploader details */}
                <div className="uploader-info">
                    <div className="avatar-box">
                        <div
                            className="avatar"
                            style={{
                                backgroundImage: `url(${avatar})`
                            }}
                            alt="profile pik"
                        ></div>
                    </div>
                    <div className="info">
                        <p className="username">{user.firstname}</p>
                        <p className="upload-date">
                            {' '}
                            {moment(`${post.created_at}`).format('MMMM Do, HH:MM:a')}
                        </p>
                    </div>
                    <div className="edit-icon" onClick={toggleSettingCard}>
                        <img
                            className="icon-img"
                            src={require('../Images/options.png').default}
                            alt="3 dot icon"
                        />

                        {showSetting && (
                            <ul className="resouce-settings">
                                {postSettingOptions.map((option, index) => (
                                    <li key={index} onClick={() => hadlePostAction(option.action)}>
                                        <img
                                            className={'option-icon'}
                                            src={option.icon}
                                            alt="icon"
                                        />
                                        <div className="option-desciption">
                                            <h5>{option.name}</h5>
                                            {option.description !== '' && (
                                                <p>{option.description}</p>
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                {/* Resource description */}
                <div className="description">
                    {type === 'videos' || type === 'movies' ? post.description : post.text}
                </div>

                <PostActions />

                {/* Comments */}
                <div className="comment-section">
                    {comments.map((comment, index) => (
                        <Comment key={index} comment={comment} />
                    ))}
                </div>

                {/* Comment form */}
                <CommentForm fixed user={user} />
            </div>
        </div>
    ) : (
        <></>
    );
}
