import { handleActions } from 'redux-actions';
import * as types from './constants';

export let EventState = {
    isLoading: false,
    events: []
};

export default handleActions(
    {
        [types.CREATE_EVENT]: (state = EventState, action) => {
            let eventList = state.events;
            eventList.unshift(action.payload);
            state = {
                isLoading: state.isLoading,
                events: eventList
            };
            return state;
        },

        [types.LOAD_EVENTS]: (state = EventState, action) => {
            state = {
                isLoading: state.isLoading,
                events: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = EventState, action) => {
            state = {
                isLoading: action.payload,
                events: state.events
            };
            return state;
        }
    },
    EventState
);
