import { handleActions } from 'redux-actions';
import * as types from './constants';

export let BeatzState = {
    isLoading: true,
    beatz: []
};

export default handleActions(
    {
        [types.CREATE_BEATZ]: (state = BeatzState, action) => {
            let beatList = state.beatz;
            beatList.unshift(action.payload);

            state = {
                isLoading: state.isLoading,
                beatz: [...beatList]
            };

            return state;
        },

        [types.LOAD_BEATZ]: (state = BeatzState, action) => {
            state = {
                isLoading: state.isLoading,
                beatz: [...action.payload]
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = BeatzState, action) => {
            state = {
                isLoading: action.payload,
                beatz: state.beatz
            };

            return state;
        }
    },
    BeatzState
);
