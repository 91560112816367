import { api as url } from '.';

const DeleteRequest = async (uri, token = '') => {
    const response = await fetch(url + uri, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        }
    });

    return response.json();
};

export { DeleteRequest };
