import { handleActions } from 'redux-actions';
import * as types from './constants';

export let ProfileState = {
    isLoading: false,
    profile: {}
};

export default handleActions(
    {
        [types.LOAD_PROFILE]: (state = ProfileState, action) => {
            state = {
                isLoading: state.isLoading,
                profile: { ...action.payload.user }
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = ProfileState, action) => {
            state = {
                isLoading: action.payload,
                profile: state.profile
            };
            return state;
        }
    },
    ProfileState
);
