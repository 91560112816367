import { handleActions } from 'redux-actions';
import * as types from './constants';

export let UserState = {
    isLoading: false,
    user: {}
};

export default handleActions(
    {
        [types.LOGIN]: (state = UserState, action) => {
            state = {
                isLoading: state.isLoading,
                user: action.payload.user
            };

            return state;
        },

        [types.REGISTER]: (state = UserState, action) => {
            state = {
                isLoading: state.isLoading,
                user: action.payload.user
            };

            return state;
        },

        [types.LOGOUT]: (state = UserState, action) => {
            state = {
                isLoading: state.isLoading,
                user: {}
            };

            return state;
        },

        [types.LOAD_USER]: (state = UserState, action) => {
            state = {
                isLoading: state.isLoading,
                user: action.payload.user ?? null
            };

            return state;
        },

        [types.UPDATE]: (state = UserState, action) => {
            state = {
                isLoading: state.isLoading,
                user: action.payload.user
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = UserState, action) => {
            state = {
                isLoading: action.payload,
                user: state.user
            };

            return state;
        }
    },
    UserState
);
