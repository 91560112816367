import { Countries } from '../..';

export const GetMusicFormFields = (artists = []) => ([
    {
        name: 'music_path',
        type: 'file',
        displayType: 'full',
        label: 'Music File',
        placeholder: 'music file',
        autofocus: true,
        required: true
    },
    {
        name: 'image_path',
        type: 'file',
        displayType: 'full',
        label: 'Music Cover',
        placeholder: 'music cover',
        autofocus: false
    },
    {
        name: 'title',
        type: 'text',
        displayType: 'full',
        label: 'Music Title',
        placeholder: 'Music title',
        autofocus: false,
        required: true
    },
    {
        name: artists.length ? 'artist_id' : 'artist_name',
        type: artists.length ? 'select' : 'text',
        placeholder: 'Music Artist',
        label: 'Music artist',
        displayType: 'full',
        autofocus: false,
        required: true,
        ...artists.length && { options: () => artists.map(artist => ({
                id: artist.id,
                name: artist.fullname
            })
        )
        }
    },
    {
        name: 'genre',
        type: 'text',
        placeholder: 'Music genre',
        label: 'Music Genre',
        autofocus: false,
        displayType: 'half'
    },
    {
        name: 'country',
        type: 'select',
        placeholder: 'Country',
        label: 'Music Origin',
        autofocus: false,
        displayType: 'half',
        required: true,
        options: () => Countries.map(c => ({
            id: c,
            name: c
        }))
    },
    {
        name: '',
        type: 'button',
        placeholder: 'Upload',
        autofocus: false,
        displayType: 'full'
    }
]);
