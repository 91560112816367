import { handleActions } from 'redux-actions';
import * as types from './constants';

export let PostState = {
    isLoading: false,
    posts: []
};

export default handleActions(
    {
        [types.CREATE_POST]: (state = PostState, action) => {
            let newPosts = state.posts;
            newPosts.unshift(action.payload);
            state = {
                isLoading: state.isLoading,
                posts: newPosts
            };
            return state;
        },

        [types.LOAD_POSTS]: (state = PostState, action) => {
            state = {
                isLoading: state.isLoading,
                posts: [...action.payload]
            };
            return state;
        },

        [types.DELETE_POST]: (state = PostState, action) => {
            state = {
                isLoading: state.isLoading,
                posts: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = PostState, action) => {
            state = {
                isLoading: action.payload,
                posts: state.posts
            };
            return state;
        }
    },
    PostState
);
