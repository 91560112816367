import * as types from './constants.js';
import { GetRequest } from '../../../Api';

export const GetUserProfile = (username, token) => {
    // async call
    return (dispatch) => {
        dispatch(Loading(true));

        const result = GetRequest({}, token, `get-user/${username}`);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_PROFILE,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
