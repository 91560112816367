export const menuItems = [
    {
        name: 'inside afrika',
        link: '/feeds',
        icon: 'news.png'
    },
    {
        name: 'followers',
        link: '/followers',
        icon: 'followers.png'
    },
    {
        name: 'artists',
        link: '/artists',
        icon: 'beatz.png',
        admin: true
    },
    {
        name: 'chat',
        link: '/chat',
        icon: 'chat.png'
    },
    {
        name: 'mp3',
        link: '/music',
        icon: 'mp3.png'
    },
    {
        name: 'dj mix',
        link: '/dj-mix',
        icon: 'mix.png'
    },

    {
        name: 'beatz',
        link: '/beatz',
        icon: 'beatz.png'
    },
    {
        name: 'videos',
        link: '/videos',
        icon: 'videos.png'
    },
    {
        name: 'movies',
        link: '/movies',
        icon: 'movies.png'
    },
    {
        name: 'albums',
        link: '/albums',
        icon: 'albums.png'
    },
    {
        name: 'events',
        link: '/events',
        icon: 'events.png'
    }
];
