import { handleActions } from 'redux-actions';
import * as types from './constants';

export let VideoState = {
    isLoading: false,
    videos: []
};

export default handleActions(
    {
        [types.CREATE_VIDEO]: (state = VideoState, action) => {
            let newVideos = state.videos;
            newVideos.unshift(action.payload);
            state = {
                isLoading: state.isLoading,
                videos: newVideos
            };
            return state;
        },

        [types.LOAD_VIDEOS]: (state = VideoState, action) => {
            state = {
                isLoading: state.isLoading,
                videos: [...action.payload]
            };
            return state;
        },

        [types.DELETE_VIDEO]: (state = VideoState, action) => {
            state = {
                isLoading: state.isLoading,
                videos: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = VideoState, action) => {
            state = {
                isLoading: action.payload,
                videos: state.videos
            };
            return state;
        }
    },
    VideoState
);
