export const postSettingOptions = [
    {
        name: 'Delete Post',
        description: '',
        action: 'delete',
        icon: require('../../Images/ViewResource/delete.png').default
    },
    {
        name: 'Edit Post',
        description: '',
        action: 'edit',
        icon: require('../../Images/ViewResource/post-notification.png').default
    },
    {
        name: 'Turn on Post Notifications',
        action: 'turnOnNotification',
        description: 'Get a notified when this individual post',
        icon: require('../../Images/ViewResource/post-notification.png').default
    },
    {
        name: 'Copy Link',
        description: '',
        action: 'copyLink',
        icon: require('../../Images/ViewResource/copy-link.png').default
    },
    {
        name: 'Download Resource',
        action: 'download',
        description: '',
        icon: require('../../Images/ViewResource/download.png').default
    }
];
