import { api as url } from './';
import serialize from './Serialize';
import axios from 'axios';

//Handles all post requests including file uploads, Bearer token is compulsory,
//only authenticated users can post data to api
const PostRequest = async (form, uri, token = '', progressObserver = () => {}, patch = false) => {
    let formData = serialize(form);

    if (patch) {
        formData.append('_method', 'patch');
    }

    const response = await axios.post(url + uri, formData, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token
        },
        onUploadProgress: progressObserver
    });

    return response.data;
   
};

export { PostRequest };
