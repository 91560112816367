import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Style/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actions, Token } from '../../';

export function FormBuilder(props) {
    const { FormFields, showTermsCondition, type, user, editMode, isLoading, addFields, id, setFields } =
        props;

    const uniqueKey = !id ? Math.floor(Math.random() * 1000) : id;

    const handleChange = (e, prop) => {
        if (!setFields) return;

        const itemIdx = FormFields.findIndex((item) => item.name === prop);
        itemIdx > -1 && (FormFields[itemIdx].value = e.target.value)
        itemIdx > -1 && setFields(FormFields)
    }

    return (
        <div className="form-builder">
            {FormFields.map((field, index) =>
                field.type === 'select' ? (
                    <div className={`form-group ${field.displayType}`} key={index}>
                        {field.hasOwnProperty('label') && <label>{field.label}</label>}
                        <select
                            key={uniqueKey}
                            name={field.name}
                            required={field.hasOwnProperty('required') ? field.required : false}
                            className={'form-control custom-select'}
                            onChange={(e) => handleChange(e, field.name)}
                        >
                            <option value={field.placeholder}>{field.placeholder}</option>
                            {field.options().map((option, index) => (
                                <option
                                    key={index}
                                    value={option.id}
                                    selected={field.value && ((typeof field.value === 'string' && option.name.toLowerCase() === field.value.toLowerCase()) || option.id === field.value)}
                                >
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : field.type === 'button' ? (
                    <div key={index} className={`form-group ${field.displayType}`}>
                        {showTermsCondition ? (
                            <div className="extra">
                                <p>
                                    By clicking Sign up, you agree to our{' '}
                                    <Link to={''}>Terms & Conditions, Privacy Policy</Link>
                                </p>
                            </div>
                        ) : (
                            ''
                        )}
                        <button className="button button-block button-dark">
                            {isLoading && (
                                <img
                                    className="loader-gif"
                                    src={require('../../Images/loader.gif').default}
                                    alt="loader"
                                />
                            )}

                            {field.placeholder}
                        </button>
                    </div>
                ) : field.type === 'textarea' ? (
                    <div className={`form-group ${field.displayType}`} key={index}>
                        {field.hasOwnProperty('label') && <label>{field.label}</label>}
                        <textarea
                            key={uniqueKey}
                            required={field.hasOwnProperty('required') ? field.required : false}
                            name={field.name}
                            defaultValue={field.hasOwnProperty('value') ? field.value : ''}
                            placeholder={field.placeholder}
                            onChange={(e) => handleChange(e, field.name)}
                            className="form-control"></textarea>
                    </div>
                ) : field.type === 'add' ? (
                    <div
                        className={'add-btn-box'}
                        key={index}
                        onClick={() => addFields(field.action)}>
                        <div className="add-btn">
                            <img
                                className="add-btn-icon"
                                src={require('../MusicList/Images/add.png').default}
                                alt="add"
                            />
                            {field.label}
                        </div>
                    </div>
                ) : (
                    <div className={`form-group ${field.displayType}`} key={index}>
                        {field.hasOwnProperty('label') && <label>{field.label}</label>}
                        <input
                            key={uniqueKey}
                            required={
                                field.hasOwnProperty('required') ? field.required : false
                            }
                            className="form-control"
                            disabled={editMode && field.type === 'email'}
                            defaultValue={
                                field.type !== 'file' && field.hasOwnProperty('value')
                                    ? field.value
                                    : ''
                            }
                            onChange={(e) => handleChange(e, field.name)}
                            name={
                                field.name === 'artist_id' && !user?.admin
                                    ? 'artist_name'
                                    : field.name
                            }
                            type={field.type}
                            placeholder={field.placeholder}
                            autoFocus={field.autofocus}
                        />
                    </div>
                )
            )}
        </div>
    );
}
