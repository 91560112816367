import { handleActions } from 'redux-actions';
import * as types from './constants';

export let ErrorState = {
    message: '',
    errors: [],
    status: 0
};

export default handleActions(
    {
        [types.ERROR_UPDATE]: (state = ErrorState, actions) => {
            return actions.payload;
        },
        [types.ERROR_CLEAR]: (state = ErrorState, actions) => {
            return actions.payload;
        }
    },
    ErrorState
);
