import * as types from './constants.js';
import { PostRequest } from '../../../Api';

export const Search = (form, token) => {
    // async call
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, 'user/search', token);
        console.log('searching..');
        result
            .then(({ data }) => {
                dispatch({
                    type: types.SEARCH,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
