import React, { useState, useRef } from 'react';
import './Style/index.scss';
import { useDispatch } from 'react-redux';
import { Token, url, PostRequest, actions } from '../../../';

export function CreatePost({ closeModal, user }) {
    const dispatch = useDispatch();

    const fileInput = useRef();

    const statusRef = useRef();

    const [uploadProgress, setUploadProgress] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [imageSample, setImgSample] = useState('');

    const [formError, setFormError] = useState('');

    const avatar = user.image
        ? `${url}/storage/avatars/${user.image}`
        : require('../../../Images/avatar.png').default;

    const progressObserver = (event) => {
        const { loaded, total } = event;
        const percent = Math.floor((loaded * 100) / total);

        setUploadProgress(percent);
    };

    const updateStore = (data) => {
        dispatch(actions.posts.CreatePost(data));
    };

    const createPost = (e) => {
        e.preventDefault();
        let postFileMissing = false;

        [...e.target.elements].forEach(el => {
            if (el.type === 'file' && !el.value) {
                setFormError('Kindly attach a post media')
                postFileMissing = true
            }
        })

        if (postFileMissing) return;

        const fields = e.target.elements;

        // I had to send the request this way bcus i needed the upload file progress on this component
        // there might be a way to add it to the redux store
        const response = PostRequest(fields, 'posts', Token, progressObserver);

        setIsLoading(true);

        response
            .then(({ data }) => {
                setIsLoading(false);
                updateStore(data);
                setUploadProgress(0);
                closeModal();
            })
            .catch(({ error }) => {
                console.log(error);
            });
    };

    const clearPostForm = () => {
        statusRef.current.value = '';
        removeImage();
    };

    const clickFileInput = () => {
        fileInput.current.click();
    };

    const removeImage = (e) => {
        setImgSample('');
        fileInput.current.value = null;
    };

    const setPreview = (e) => {
        let img = URL.createObjectURL(e.target.files[0]);
        setFormError('')
        setImgSample(img);
    };

    return (
        <div className="create-post-wrapper">
            <div className="create-post-box">
                <img
                    className="close-post-box"
                    onClick={() => {
                        closeModal();
                        clearPostForm();
                    }}
                    src={require('../Images/close-modal.png').default}
                    alt="close modal icon"
                />

                <h3 className="create-post-header">Create Post</h3>

                <div className="post-settings">
                    <div
                        className="post-setting-avatar"
                        style={{
                            backgroundImage: `url(${avatar})`
                        }}
                    ></div>

                    <div className={'post-visibility'}>
                        <h5>
                            {user.firstname} {user.lastname}
                        </h5>
                        <div className="visibility-setting">
                            <img
                                src={require('./Images/followers.png').default}
                                alt="followers"
                            ></img>
                            <span>Followers</span>
                            <img src={require('./Images/caret.png').default} alt="caret" />
                        </div>
                    </div>
                </div>

                <div className="create-post-body">
                    <form onSubmit={createPost}>
                        <textarea
                            ref={statusRef}
                            name="text"
                            placeholder={"What's on your mind..?"}
                        ></textarea>

                        <div className={'post-preview'}>
                            {imageSample !== '' ? (
                                <div className="preview">
                                    <span onClick={removeImage}>X</span>

                                    <img src={imageSample} alt="sample" />
                                </div>
                            ) : (
                                <></>
                            )}

                            {uploadProgress !== 0 && (
                                <div className="progress-bar">
                                    <div
                                        className="progress-loader"
                                        style={{ width: `${uploadProgress}%` }}
                                    >
                                        <span>{uploadProgress}%</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <input type="hidden" name="post_type" value={'user'} />
                        <input type="hidden" name="user_id" value={user.id} />

                        <input
                            type="file"
                            id="post-img"
                            ref={fileInput}
                            accept=""
                            onChange={setPreview}
                            name="image"
                        />

                        {
                            formError && <p className={'error-msg'}>{formError}</p>
                        }

                        <div className="create-post-footer">
                            <span>Add to Your Post</span>

                            <img
                                onClick={clickFileInput}
                                src={require('./Images/photo.png').default}
                                alt="icon"
                            />
                            <img src={require('./Images/video.png').default} alt="video" />
                            <img src={require('./Images/tag.png').default} alt="tag" />
                            <img src={require('./Images/location.png').default} alt="location" />
                        </div>

                        <button className="btn btn-dark">POST</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
