import React from 'react';

export function MusicListActions({ songs }) {
    return (
        <div className="music-actions">
            <button className={'music-action-btn btn-colored'}>
                Play <span>All</span>
            </button>
            <button className={'music-action-btn btn-colored'}>
                <img src={require('./Images/shuffle.png').default} alt="shuffle" />{' '}
                <span>Shuffle All</span>({songs.length})
            </button>
            <button className={'music-action-btn btn-colored'}>
                <img src={require('./Images/add.png').default} alt="add" />{' '}
                <span>Add To Playlist</span>
            </button>
            <button className={'music-action-btn'}>
                <img src={require('./Images/share.png').default} alt="share" />
            </button>
            <button className={'music-action-btn'}>
                <img src={require('./Images/download.png').default} alt="download" />
            </button>
            <button className={'music-action-btn btn-colored'}>
                <img src={require('./Images/info.png').default} alt="info" />
            </button>
        </div>
    );
}
