import { handleActions } from 'redux-actions';
import * as types from './constants';
import { cloneDeep } from 'lodash'

export let NewsState = {
    isLoading: false,
    news: [],
    generalNews: []
};

export default handleActions(
    {
        [types.CREATE_NEWS]: (state = NewsState, action) => {
            let generalNewsList = state.generalNews;
            let newsList = state.news;

            generalNewsList.unshift(action.payload);
            newsList.unshift(action.payload);

            state = {
                isLoading: state.isLoading,
                generalNews: generalNewsList,
                news: newsList
            };

            return state;
        },

        [types.LOAD_PERSONAL_NEWS]: (state = NewsState, action) => {
            state = {
                isLoading: state.isLoading,
                news: [...action.payload],
                generalNews: []
            };

            return state;
        },

        [types.LOAD_GENERAL_NEWS]: (state = NewsState, action) => {
            state = {
                isLoading: state.isLoading,
                generalNews: [...action.payload],
                news: []
            };

            return state;
        },

        [types.UPDATE_GENERAL_NEWS]: (state = NewsState, action) => {
            const news = action.payload;

            const generalNews = cloneDeep(state.generalNews)

            const i = generalNews.findIndex(n => n.id === parseInt(news.id));

            if (i > -1) generalNews[i].story = news.story;

            state = {
                isLoading: state.isLoading,
                news: state.news,
                generalNews: generalNews
            };

            return state;
        },

        [types.NEWS_ADD_COMMENT]: (state = NewsState, action) => {
            let comment = action.payload;

            const news = cloneDeep(state.news)
            const generalNews = cloneDeep(state.generalNews)

            const newIdx = news.findIndex((n) => n.id === parseInt(comment.comment_id));
            if (newIdx > -1) news[newIdx].comments.push(comment);

            if (newIdx === -1) {
                const idx = generalNews.findIndex((n) => n.id === parseInt(comment.comment_id));
                idx > -1 && generalNews[idx].comments.push(comment);
            }

            state = {
                isLoading: state.isLoading,
                news: news,
                generalNews: generalNews
            };

            return state;
        },

        [types.LIKE_NEWS]: (state = NewsState, action) => {
            let { message, like } = action.payload;

            const news = cloneDeep(state.news)
            const generalNews = cloneDeep(state.generalNews)

            let found = false;

            news.forEach((n) => {
                if (n.id === parseInt(like.like_id)) {
                    if (message === 'liked') {
                        n.likes.push(like);
                        found = true;
                    } else {
                        n.likes.forEach((l, i) => {
                            if (l.id === like.id) n.likes.splice(i, 1);
                        });

                        found = true;
                    }
                }
            });

            if (!found) {
                generalNews.forEach((n) => {
                    if (n.id === parseInt(like.like_id)) {
                        if (message === 'liked') {
                            n.likes.push(like);
                            found = true;
                        } else {
                            n.likes.forEach((l, i) => {
                                if (l.id === like.id) n.likes.splice(i, 1);
                            });

                            found = true;
                        }
                    }
                });
            }

            state = {
                isLoading: state.isLoading,
                news: news,
                generalNews: generalNews
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = NewsState, action) => {
            state = {
                isLoading: action.payload,
                generalNews: state.generalNews,
                news: state.news
            };

            return state;
        }
    },
    NewsState
);
