import * as types from './constants.js';
import { Loading as MovieLoading } from '../Movies/actions';
import { GetRequest, DeleteRequest } from '../../../Api';

export const CreateMovie = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_MOVIE,
            payload: data
        });
    };
};

export const GetMovies = (token) => {
    return (dispatch) => {
        dispatch(MovieLoading(true));

        let result = GetRequest({}, token, 'movies');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_MOVIES,
                    payload: data
                });
                dispatch(MovieLoading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(MovieLoading(false));
            });
    };
};

export const DeleteMovie = (id, token) => {
    return (dispatch) => {
        const result = DeleteRequest(`movies/${id}`, token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.DELETE_MOVIE,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
