import * as types from './constants.js';
import { Loading as EventLoading } from '../Events/actions';
import { GetRequest } from '../../../Api';

export const CreateEvent = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_EVENT,
            payload: data
        });
    };
};

export const GetEvents = (token) => {
    return (dispatch) => {
        dispatch(EventLoading(true));

        let result = GetRequest({}, token, 'events');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_EVENTS,
                    payload: data
                });
                dispatch(EventLoading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(EventLoading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
