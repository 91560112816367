import * as types from './constants.js';
import { GetRequest } from '../../../Api';

export const CreateBeat = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_BEATZ,
            payload: data
        });
    };
};

export const GetBeat = (token) => {
    return (dispatch) => {
        dispatch(SongsLoading(true));

        let result = GetRequest({}, token, 'beatz');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_BEATZ,
                    payload: data
                });
                dispatch(SongsLoading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(SongsLoading(false));
            });
    };
};

export const SongsLoading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
