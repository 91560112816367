import * as types from './constants.js';
import { PostRequest, GetRequest } from '../../../Api';

export const AddComment = (form, token) => {
    return (dispatch) => {
        dispatch(LoadingComments(true));

        const result = PostRequest(form, 'comments', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.ADD_COMMENT,
                    payload: data
                });
                dispatch(LoadingComments(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(LoadingComments(false));
            });
    };
};

export const LoadComments = (token, id, body) => {
    return (dispatch) => {
        dispatch(LoadingComments(true));

        let result = GetRequest(body, token, 'comments/' + id);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_COMMENTS,
                    payload: data
                });
                dispatch(LoadingComments(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(LoadingComments(false));
            });
    };
};

export const LoadingComments = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
