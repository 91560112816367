import * as types from './constants.js';
import { GetRequest } from '../../../Api';

export const CreateMusic = (music) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_MUSIC,
            payload: music
        });
    };
};

export const GetPlaylist = (token) => {
    return (dispatch) => {
        dispatch(SongsLoading(true));

        let result = GetRequest({}, token, 'playlist');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_PLAYLIST,
                    payload: data
                });
                dispatch(SongsLoading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(SongsLoading(false));
            });
    };
};

export const SongsLoading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
