import React from 'react';
import './Style/index.scss';
import { url } from '../../';

export function Comment({ comment }) {
    const avatar = comment.user.image
        ? `${url}/storage/avatars/${comment.user.image}`
        : require('../../Images/avatar.png').default;

    return (
        <div className="comment-box">
            <div
                className={'avatar'}
                style={{
                    backgroundImage: `url(${avatar})`
                }}
            ></div>

            <div className="comment-content">
                <div className="comment">
                    <p className="username">{comment.user.firstname}</p>
                    <p className="content"> {comment.comment}</p>
                </div>
            </div>
        </div>
    );
}
