import * as types from './constants.js';
import { GetRequest, DeleteRequest } from '../../../Api';

export const CreateVideo = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_VIDEO,
            payload: data
        });
    };
};

export const GetVideos = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'videos');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_VIDEOS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const DeleteVideo = (id, token) => {
    return (dispatch) => {
        const result = DeleteRequest(`videos/${id}`, token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.DELETE_VIDEO,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
