import { handleActions } from 'redux-actions';
import * as types from './constants';

export let MovieState = {
    isLoading: false,
    movies: []
};

export default handleActions(
    {
        [types.CREATE_MOVIE]: (state = MovieState, action) => {
            let newMovieList = state.movies;
            newMovieList.unshift(action.payload);
            state = {
                isLoading: state.isLoading,
                movies: [...newMovieList]
            };
            return state;
        },

        [types.LOAD_MOVIES]: (state = MovieState, action) => {
            state = {
                isLoading: state.isLoading,
                movies: [...action.payload]
            };
            return state;
        },

        [types.DELETE_MOVIE]: (state = MovieState, action) => {
            state = {
                isLoading: state.isLoading,
                movies: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = MovieState, action) => {
            state = {
                isLoading: action.payload,
                movies: state.movies
            };
            return state;
        }
    },
    MovieState
);
