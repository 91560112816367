import { handleActions } from 'redux-actions';
import * as types from './constants';

export let MixState = {
    isLoading: true,
    mixes: []
};

export default handleActions(
    {
        [types.CREATE_MIX]: (state = MixState, action) => {
            let mixList = state.mixes;
            mixList.unshift(action.payload);

            state = {
                isLoading: state.isLoading,
                mixes: [...mixList]
            };

            return state;
        },

        [types.LOAD_MIX]: (state = MixState, action) => {
            state = {
                isLoading: state.isLoading,
                mixes: [...action.payload]
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = MixState, action) => {
            state = {
                isLoading: action.payload,
                mixes: state.mixes
            };

            return state;
        }
    },
    MixState
);
