import React, { useRef, useState } from 'react';
import { MusicListActions } from './MusicListActions';
import './Style/index.scss';
import { url, MusicPlayerContext, ModalOverlay, UploadModal, GetMusicFormFields } from '../../';
import { Spinner } from '../Spinner';
import { cloneDeep } from 'lodash';

const MusicList = React.forwardRef((props, ref) => {
    const EditMusicModalRef = useRef();

    const { songs, user, isLoading, name, type, artists, reload } = props;

    const [id, setId] = useState(0);

    const [songFields, setSongFields] = useState([]);

    const updateSong = (song) => {
        const uniqueKey = !id ? Math.floor(Math.random() * 1000) : id;

        let fields = cloneDeep(GetMusicFormFields(artists));

        fields.forEach((field) => {
            field.value = song[field.name];
            if (field.type === 'file') {
                field.required = false;
            }
            return field;
        });

        setId(uniqueKey);

        setSongFields(fields);

        EditMusicModalRef.current.open();
    };

    return (
        <MusicPlayerContext.Consumer>
            {(context) => (
                <>
                    <div>
                        <ModalOverlay ref={EditMusicModalRef}>
                            <UploadModal
                                reload={reload}
                                type={type}
                                user={user}
                                key={id}
                                id={context.songId}
                                title={'Edit Song'}
                                editResource={true}
                                fields={songFields}
                            />
                        </ModalOverlay>
                    </div>

                    <MusicListActions songs={songs} />

                    <table className={'music-list'}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th></th>
                                <th>Title</th>
                                <th>Artist</th>
                                <th>Length</th>
                                <th>
                                    <input type="checkbox" name="chosenids[]" />
                                </th>
                                {/* <th>Edit</th> */}
                            </tr>
                        </thead>

                        <tbody ref={context.playListRef}>
                            {isLoading ? (
                                <tr>
                                    <td colSpan={6}>
                                        <Spinner />{' '}
                                    </td>
                                </tr>
                            ) : songs.length > 0 ? (
                                songs.map((song, index) => (
                                    <tr
                                        onClick={(e) => {
                                            context.clickSong(e);
                                        }}
                                        data-title={song.title}
                                        data-artist={song.artist_name}
                                        data-id={song.id}
                                        data-src={`${url}/storage/${name}Audio/${song.music_path}`}
                                        data-cover={
                                            song.image_path === 'undefined'
                                                ? require('../Layout/UniversalLayout/Images/default-music.png')
                                                      .default
                                                : `${url}/storage/${name}Cover/${song.image_path}`
                                        }
                                        key={index}>
                                        <td className="song-index">{index + 1}</td>
                                        <td className="td-play-btn">
                                            <img
                                                src={require('./Images/playing.png').default}
                                                alt="playing"
                                            />
                                        </td>
                                        <td className="song-title">{song.title}</td>
                                        <td className="song-artist">
                                            {song.hasOwnProperty('artist_name')
                                                ? song.artist_name
                                                : song.dj}
                                        </td>
                                        <td className="song-duration">{song.duration}</td>
                                        <td className="song-check">
                                            <input
                                                type="checkbox"
                                                name="chosenids[]"
                                                onClick={() => updateSong(song)}
                                            />
                                        </td>
                                        {/* <td>
                                            <div
                                                className="edit-resource"
                                                onClick={() => {
                                                    updateSong(song);
                                                }}>
                                                <img
                                                    src={require('../../Images/edit.png').default}
                                                    alt="edit"
                                                />
                                            </div>
                                        </td> */}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={6} className="no-content">
                                        {' '}
                                        No Songs
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            )}
        </MusicPlayerContext.Consumer>
    );
});

export { MusicList };
