import * as types from './constants.js';
import { actions } from '..';
import { PostRequest, GetRequest } from '../../../Api';

export const Create = (form, token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, 'follow', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.CREATE_FOLLOWER,
                    payload: data
                });
                dispatch(Loading(false));
                dispatch(actions.notification.SetMessage('Following!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const LoadFollowers = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'follow');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_FOLLOWERS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
