import { handleActions } from 'redux-actions';
import * as types from './constants';

export let ArtistState = {
    isLoading: false,
    artists: []
};

export default handleActions(
    {
        [types.CREATE_ARTIST]: (state = ArtistState, action) => {
            let artistList = state.artists;
            artistList.unshift(action.payload);

            state = {
                isLoading: state.isLoading,
                artists: artistList
            };

            return state;
        },

        [types.LOAD_ARTISTS]: (state = ArtistState, action) => {
            state = {
                isLoading: state.isLoading,
                artists: [...action.payload]
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = ArtistState, action) => {
            state = {
                isLoading: action.payload,
                artists: state.artists
            };

            return state;
        }
    },
    ArtistState
);
