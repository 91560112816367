import React from 'react';
import { PostActions, Comment, CommentForm, url } from '../..';
import './Style/index.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { eventBus } from '../../../eventBus';

export function NewsCard({ user: authUser, news }) {
    const { user, story, comments, id, likes, created_at } = news;

    const avatar = user.image
        ? `${url}/storage/avatars/${user.image}`
        : require('../../Images/avatar.png').default;

    const editDispatcher = () => {
        eventBus.dispatch("edit-news", {
            id,
            content: story
        });
    }

    return (
        <>
            <div className="news-card">
                <div className="card-owner-details">
                    <Link
                        to={`/profile/${user.username}`}
                        className="avatar"
                        style={{
                            backgroundImage: `url(${avatar})`
                        }}
                    ></Link>

                    <div className="name-date">
                        <p className="name">
                            {user.firstname} <span>shared a post</span>
                        </p>
                        <p className="date">{moment(`${created_at}`).format('MMMM Do, HH:MM:a')}</p>
                    </div>

                    <div className="more-actions">
                        {
                            authUser.id === user.id && <img
                                src={require('../../Images/edit.png').default}
                                alt="edit"
                                onClick={editDispatcher}
                            />
                        }
                    </div>
                </div>

                <div className="resource">

                </div>

                <div className={'news-content'}>
                    <div className="news-html-content">{ parse(story) }</div>

                    <div className="news-stats">
                        <div className="stats like-stats">
                            <span>{likes.length} Like(s)</span>
                        </div>
                        {/* <div className="stats share-stats">
                                <span>4k Shares</span>
                            </div>
                            <div className="stats views-stats">
                                <span>484k Views</span>
                            </div> */}
                        <div className="stats comment-stats">
                            <span>{comments.length} Comment(s)</span>
                        </div>
                    </div>

                    {/* Actions */}
                    <PostActions userId={authUser.id} likeId={id} />

                    {comments.map((comment, index) => (
                        <Comment comment={comment} key={index} />
                    ))}

                    {/* Comment form */}
                    <CommentForm user={authUser} id={id} />
                </div>
            </div>
        </>
    );
}
