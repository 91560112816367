import React from 'react';
import './Style/index.scss';

export function MusicPlayer(props) {
    const {
        cover,
        title,
        artist,
        repeat,
        playing,
        prev,
        next,
        play,
        playedTime,
        currentTimeLength,
        audioLength,
        volumeRef
    } = props;

    return (
        <div className="music-player-area">
            <div
                className={'music-cover'}
                style={{
                    backgroundImage: `url(${cover})`
                }}
            ></div>

            {/* Player music info */}
            <div className={'audio-details'}>
                <h4 className="audio-name">{title}</h4>
                <p className="audio-artist">{artist}</p>
            </div>

            {/* Music player main controllers */}
            <div className="audio-controls">
                <div className="controls">
                    <span className="repeat" onClick={repeat}>
                        <img src={require('./Images/repeat.png').default} alt="repeat icon" />
                    </span>
                    <span className="previous" onClick={prev}>
                        <img src={require('./Images/prev.png').default} alt="previous icon" />
                    </span>
                    <span className="play" onClick={(e) => play(e)}>
                        {playing ? (
                            <img src={require('./Images/pause.png').default} alt="pause icon" />
                        ) : (
                            <img src={require('./Images/play.png').default} alt="play icon" />
                        )}
                    </span>
                    <span className="next" onClick={next}>
                        <img src={require('./Images/next.png').default} alt="next icon" />
                    </span>
                    <span className="shuffle">
                        <img src={require('./Images/shuffle.png').default} alt="shuffle icon" />
                    </span>
                </div>
                <div className="duration-tracker">
                    <span className="current-time time">{playedTime}</span>
                    <div className="current-tracker" style={{ width: currentTimeLength }}></div>
                    <span className="total-time time">{audioLength}</span>
                </div>
            </div>

            {/* Music pplayer volume controller */}
            <div className="volume-box">
                <img src={require('./Images/volume.png').default} alt="volume" />
                <div className="volume-tracker" ref={volumeRef}>
                    <div className="tracker"></div>
                </div>
            </div>
        </div>
    );
}
