import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import './Style/index.scss';
import { Token, actions } from '../../../../';

export function SettingsDropdown({ url, active, user }) {
    const dispatch = useDispatch();

    const avatar = user.image
        ? `${url}/storage/avatars/${user.image}`
        : require('../../../../Images/avatar.png').default;

    const logout = () => {
        dispatch(actions.user.Logout(Token));
    };

    return (
        <div className={`${active ? 'active' : ''} nav-dropdown`}>
            <ul className="dropdown-list">
                <li>
                    <div
                        className="dropdown-avatar"
                        style={{
                            backgroundImage: `url(${avatar})`
                        }}
                    ></div>
                    <Link to={'/profile'}>{user.firstname}</Link>
                </li>
                <li>
                    <img
                        src={require('../../../../Images/TopNavMenu/settings.png').default}
                        alt="settings"
                    />
                    <Link to={'/settings'}>Settings & Privacy</Link>
                </li>
                <li>
                    <img
                        src={require('../../../../Images/TopNavMenu/support.png').default}
                        alt="support"
                    />
                    <Link to={'/support'}>Help & Support</Link>
                </li>
                <li>
                    <img
                        src={require('../../../../Images/TopNavMenu/logout.png').default}
                        alt="logout"
                    />
                    <Link to={'#'} onClick={logout}>
                        Sign Out
                    </Link>
                </li>
            </ul>
        </div>
    );
}
