import { handleActions } from 'redux-actions';
import * as types from './constants';

export let AppState = {
    loader: false
};

export default handleActions(
    {
        [types.SET_LOADING]: (state = AppState, action) => {
            state = {
                loader: action.payload
            };
            return state;
        }
    },
    AppState
);
