import { handleActions } from 'redux-actions';
import * as types from './constants';

export let AlbumState = {
    isLoading: false,
    albums: []
};

export default handleActions(
    {
        [types.CREATE_ALBUM]: (state = AlbumState, action) => {
            let albumList = state.albums;
            albumList.unshift(action.payload);

            state = {
                isLoading: state.isLoading,
                albums: albumList
            };

            return state;
        },

        [types.ADD_MUSIC]: (state = AlbumState, action) => {
            let albumList = state.albums;
            let album = albumList.find((al) => al.id === parseInt(action.payload.album_id));
            album.songs.push(action.payload.song);

            state = {
                isLoading: state.isLoading,
                albums: albumList
            };

            return state;
        },

        [types.LOAD_ALBUMS]: (state = AlbumState, action) => {
            state = {
                isLoading: state.isLoading,
                albums: [...action.payload]
            };
            return state;
        },

        [types.LOADING_STATUS]: (state = AlbumState, action) => {
            state = {
                isLoading: action.payload,
                albums: state.albums
            };
            return state;
        }
    },
    AlbumState
);
