export const errorCodes = {
    429: "Too many attemps",
    404: "not found",
    500: "internal server error",
    401: "Unauthorized"
}

export const goodResponseStatusCodes = [200, 201]

export const isErrorResponse = (code) => {
    return !goodResponseStatusCodes.includes(code)
}


export const isUserUnauthenticated = (res) => {
    // it should be 401
    return (res.status === 500 && res.message === 'Unauthenticated.') || res.status === 401;
}