import React from 'react';
import './Style/index.scss';
import { url } from '../../../';

export function VideoCard({ video, type, context, viewVideo, updateVideo }) {
    return (
        <>
            <div className="video-card">
                <div
                    className="video-thumbnail"
                    onClick={() => {
                        viewVideo(video);
                    }}
                    style={{
                        backgroundImage: `url(${url}/storage/${type}Thumbnail/${video.thumbnail})`
                    }}
                ></div>

                {context && context !== 'news' && (
                    <>
                        <h4>{video.title}</h4>
                        <p>{video.views.length} Views</p>
                        <div
                            className="edit-resource"
                            onClick={() => {
                                updateVideo(video);
                            }}
                        >
                            <img src={require('../../../Images/edit.png').default} alt="edit" />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
