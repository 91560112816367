import React from 'react';
import '../Styling/spinner.scss';

const Spinner = () => {
    return (
        <div className={'spinner'}>
            <img src={require('../Images/spinner.gif').default} alt="spinner"></img>
        </div>
    );
};

export { Spinner };
