import React, { useRef } from 'react';
import './Style/index.scss';
import { ModalOverlay, UploadModal, MobileSearh, MobileNavigator } from '../../';

export function SearchBox(props) {
    const modalRef = useRef();

    const { searchText, uploadFormFields, setData, user, type, uploadFormTitle, reload } = props;

    const search = (e) => {
        e.preventDefault();
    };

    return (
        <>
            {/* Navigator for mobile view */}
            <MobileNavigator title={type} modalRef={modalRef} />

            <MobileSearh placeholder={searchText} />

            <div className="search-box">
                <form onSubmit={search}>
                    <input
                        type="text"
                        placeholder={searchText}
                        className="form-control"
                        name="search"
                    />
                    <button>Search</button>
                </form>

                <button onClick={() => modalRef.current.open()} className={'upload-btn'}>
                    <img src={require('./Images/upload.png').default} alt="upload" /> upload
                </button>
            </div>

            <ModalOverlay ref={modalRef}>
                <UploadModal
                    reload={reload}
                    setData={setData}
                    type={type}
                    user={user}
                    title={uploadFormTitle}
                    fields={uploadFormFields}
                />
            </ModalOverlay>
        </>
    );
}
