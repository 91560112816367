import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import './Style/index.scss';
import { url, actions, Token } from '../../';

export function CommentForm({ fixed, user, id }) {
    const dispatch = useDispatch();

    const inputRef = useRef();

    const avatar = user.image
        ? `${url}/storage/avatars/${user.image}`
        : require('../../Images/avatar.png').default;

    const addComment = (e) => {
        e.preventDefault();
        dispatch(actions.news.AddComment(e.target.elements, Token));
        inputRef.current.value = '';
    };

    return (
        <div className={fixed ? 'fixed comment-form' : 'comment-form'}>
            <div
                className={'form-avatar'}
                style={{
                    backgroundImage: `url( ${avatar} )`
                }}
            ></div>

            <form onSubmit={addComment}>
                <input type="hidden" name="type" value="news" />
                <input type="hidden" name="resource_id" value={id} />
                <input type="hidden" name="user_id" value={user.id} />
                <input
                    type="text"
                    ref={inputRef}
                    name="comment"
                    className="form-control"
                    placeholder="Write a comment.."
                />
            </form>
        </div>
    );
}
