import * as types from './constants.js';
import { actions } from '../index';
import { PostRequest, GetRequest } from '../../../Api';

export const Create = (form, token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, 'conversation', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.CREATE_CONVERSATION,
                    payload: data
                });
                dispatch(Loading(false));
                dispatch(actions.notification.SetMessage('Conversation started!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Load = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = GetRequest({}, token, 'conversation');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_CONVERSATIONS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const AddMessage = (form, token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, 'message', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.ADD_MESSAGE_TO_DB,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const UpdateMessagesFromSocket = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.ADD_MESSAGE,
            payload: data
        });

        // dispatch(actions.notification.SetMessage("msg!"));
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
