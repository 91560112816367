/* eslint-disable import/no-anonymous-default-export */
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { actions } from '../Store';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner, UniversalLayout, Token } from '../Shared';
import {
    Djmix,
    Beatz,
    Mp3,
    Albums,
    AuthLayout,
    Artists,
    News,
    UserProfile,
    PasswordReset,
    ForgotPassword,
    Register,
    Login,
    Videos,
    Movies,
    Chat,
    Settings,
    Followers,
    EventPage,
    OtherUserProfile,
    EventView,
    AlbumView,
    Error404
} from './Pages';

//Router Component
export default function () {
    let dispatch = useDispatch();

    let { userPayload } = useSelector((state) => ({
        userPayload: state.user
    }));

    let [loading, setLoading] = useState(true);

    let { user } = userPayload;

    let location = useLocation();

    useEffect(() => {
        if (user && Object.entries(user).length === 0 && Token !== null) {
            dispatch(actions.user.GetUser(Token));
        }
    }, [dispatch, user]);

    let loggedIn = user !== null && Object.keys(user).length !== 0;

    if ((loading && (loggedIn || Token === null)) || (loading && user === null)) setLoading(false);

    if (loading) {
        return <Spinner />;
    } else {
        return (
            <Suspense fallback={<Spinner />}>
                <AuthLayout loggedIn={loggedIn}>
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            {/* Authentication routes */}
                            <UnauthRouter
                                exact
                                path={'/reset-password'}
                                user={user}
                                Component={PasswordReset}
                            />
                            <UnauthRouter
                                exact
                                path={'/forgot-password'}
                                user={user}
                                Component={ForgotPassword}
                            />
                            <UnauthRouter exact path={'/login'} user={user} Component={Login} />
                            <UnauthRouter
                                exact
                                path={'/register'}
                                user={user}
                                Component={Register}
                            />
                        </Switch>
                    </Suspense>
                </AuthLayout>

                <UniversalLayout
                    user={user}
                    reminderColumn={location.pathname === '/' || location.pathname === '/feeds'}
                    showSideMenu={!location.pathname.includes('/profile')}
                >
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route path="/404" component={Error404} />

                            {/* Protected routes for only logged in users */}
                            <ProtectedRouter exact path={'/'} user={user} Component={News} />
                            <ProtectedRouter exact path={'/chat'} user={user} Component={Chat} />
                            <ProtectedRouter
                                exact
                                path={'/artists'}
                                isAdmin
                                user={user}
                                Component={Artists}
                            />
                            <ProtectedRouter
                                exact
                                path={'/followers'}
                                user={user}
                                Component={Followers}
                            />
                            <ProtectedRouter
                                exact
                                path={'/settings'}
                                user={user}
                                Component={Settings}
                            />
                            <ProtectedRouter exact path={'/feeds'} user={user} Component={News} />
                            <ProtectedRouter
                                exact
                                path={'/albums'}
                                user={user}
                                Component={Albums}
                            />
                            <ProtectedRouter
                                exact
                                path={'/album/:id'}
                                user={user}
                                Component={AlbumView}
                            />
                            <ProtectedRouter exact path={'/music'} user={user} Component={Mp3} />
                            <ProtectedRouter exact path={'/dj-mix'} user={user} Component={Djmix} />
                            <ProtectedRouter exact path={'/beatz'} user={user} Component={Beatz} />
                            <ProtectedRouter
                                exact
                                path={'/profile'}
                                user={user}
                                Component={UserProfile}
                            />
                            <ProtectedRouter
                                exact
                                path={'/profile/:id'}
                                user={user}
                                Component={OtherUserProfile}
                            />
                            <ProtectedRouter
                                exact
                                path={'/movies'}
                                user={user}
                                Component={Movies}
                            />
                            <ProtectedRouter
                                exact
                                path={'/videos'}
                                user={user}
                                Component={Videos}
                            />
                            <ProtectedRouter
                                exact
                                path={'/events'}
                                user={user}
                                Component={EventPage}
                            />
                            <ProtectedRouter
                                exact
                                path={'/event/:id'}
                                user={user}
                                Component={EventView}
                            />
                        </Switch>
                    </Suspense>
                </UniversalLayout>
            </Suspense>
        );
    }
}

//This Component handles routes that are accessible by only authenticated users
const ProtectedRouter = ({ user, Component, path, computedMatch, isAdmin }) => {
    let loggedIn = user !== null && Object.keys(user).length !== 0;

    return (
        <>
            {loggedIn ? (
                isAdmin ? (
                    user.admin ? (
                        <Route
                            exact
                            path={path}
                            render={() => <Component match={computedMatch} user={user} />}
                        />
                    ) : (
                        <Redirect to={'/404'} />
                    )
                ) : (
                    <Route
                        exact
                        path={path}
                        render={() => <Component match={computedMatch} user={user} />}
                    />
                )
            ) : (
                <Redirect to={'/login'} />
            )}
        </>
    );
};

//This Component redirects away from authentication pages, if user is already logged in
const UnauthRouter = ({ user, Component, path }) => {
    let loggedIn = user !== null && Object.keys(user).length !== 0;

    return (
        <>
            {loggedIn ? (
                <Redirect to={'/'} />
            ) : (
                <Route exact path={path} render={() => <Component user={user} />} />
            )}
        </>
    );
};
