import React, { useRef, forwardRef, useState, useImperativeHandle, cloneElement } from 'react';
import './Style/index.scss';

export const ModalOverlay = forwardRef((props, ref) => {
    const modalOverlayRef = useRef();

    const [closed, setClosed] = useState(true);

    const closeModal = () => {
        modalOverlayRef.current.style.visibility = 'hidden';
        modalOverlayRef.current.style.opacity = 0;

        let form = modalOverlayRef.current.querySelector('form');

        if (form) {
            const inputs = form.querySelectorAll('input, textarea, select');

            inputs.forEach((element) => {
                let { type } = element;

                switch (type) {
                    case 'select-one':
                        element.value = 'Country';
                        break;
                    case 'hidden':
                        break;
                    default:
                        element.value = '';
                        break;
                }
            });
        }

        setClosed(true);
    };

    const openModal = () => {
        modalOverlayRef.current.style.visibility = 'visible';
        modalOverlayRef.current.style.opacity = 1;
        setClosed(false);
    };

    const handleModalClose = (e) => {
        if (
            e.target.classList.contains('modal-overlay') ||
            e.target.classList.contains('recovery-box')
        ) {
            closeModal();
        }
    };

    useImperativeHandle(ref, () => ({
        open: openModal,
        close: closeModal
    }));

    return (
        <div className="modal-overlay" ref={modalOverlayRef}>
            {cloneElement(props.children, { closeModal: closeModal, isClosed: closed })}
        </div>
    );
});
