import * as types from './constants.js';
import { actions } from '../';
import { Update } from '../Error/actions';
import { PostRequest, GetRequest } from '../../../Api';
import { isErrorResponse, isUserUnauthenticated } from '../../../Shared';

export const Login = (form) => {
    // async call
    return (dispatch) => {
        dispatch(actions.app.loading(true));
        dispatch(Loading(true));

        const result = PostRequest(form, 'login');

        result
            .then((res) => {
                const { data, ...rest } = res;
                if (isErrorResponse(res.status)) {
                    dispatch(Update({ ...rest }));
                } else {
                    dispatch({
                        type: types.LOGIN,
                        payload: {
                            ...data
                        }
                    });
                    localStorage.setItem('token-afrisocialworld', data.token);
                    window.location.reload();
                }
            }).catch(({ response }) => {
                const { data, ...rest } = response.data;
                dispatch(Update({ ...rest }));
            }).finally(() => {
                dispatch(actions.app.loading(false));
                dispatch(Loading(false));
            });
    };
};

export const Register = (form) => {
    // async call
    return (dispatch) => {
        dispatch(actions.app.loading(true));
        dispatch(Loading(true));

        const result = PostRequest(form, 'register');

        result
            .then((res) => {
                const { data, ...rest } = res;
                if (isErrorResponse(res.status)) {
                    dispatch(Update({ ...rest }));
                } else {
                    dispatch({
                        type: types.REGISTER,
                        payload: {
                            ...data
                        }
                    });
                    localStorage.setItem('token-afrisocialworld', data.token);
                    window.location.reload();
                }
            }).catch(({ response }) => {
                const { data, ...rest } = response.data;
                dispatch(Update({ ...rest }));
            }).finally(() => {
                dispatch(actions.app.loading(false));
                dispatch(Loading(false));
            });
    };
};


export const GetUser = (token) => {
    // async call
    return (dispatch) => {
        dispatch(actions.app.loading(true));
        dispatch(Loading(true));

        const result = GetRequest({}, token, 'user');

        result.then((res) => {
            const { data, ...rest } = res;
            if (isUserUnauthenticated(res)) {
                localStorage.removeItem('token-afrisocialworld');
                window.location.href = '/login';
                return;
            }
            if (isErrorResponse(res.status)) {
                dispatch(Update({ ...rest }));
            } else {
                dispatch({
                    type: types.LOAD_USER,
                    payload: data
                });
            }}).catch(({ response }) => {
                const { data, ...rest } = response.data;
                dispatch(Update({ ...rest }));
            }).finally(() => {
                dispatch(actions.app.loading(false));
                dispatch(Loading(false));
            });
    };
};

export const UpdateUser = (id, form, token) => {
    // async call
    return (dispatch) => {
        dispatch(actions.app.loading(true));
        dispatch(Loading(true));

        const result = PostRequest(form, `user/${id}`, token);

        result
            .then(res => {
                const { data, ...rest } =res;
                if (isErrorResponse(res.status)) {
                    dispatch(Update({ ...rest }));
                } else {
                    dispatch({
                        type: types.UPDATE,
                        payload: data
                    });
                }
                dispatch(actions.notification.SetMessage('Profile updated!'));
            }).catch(({ response }) => {
                const { data, ...rest } = response.data;
                dispatch(Update({ ...rest }));
            }).finally(() => {
                dispatch(actions.app.loading(false));
                dispatch(Loading(false));
            });
    };
};

export const Logout = (token) => {
    // async call
    return (dispatch) => {
        dispatch(actions.app.loading(true));
        dispatch(Loading(true));

        const result = PostRequest({}, 'logout', token);

        result
            .then(({ data }) => {
                localStorage.removeItem('token-afrisocialworld');
                dispatch({
                    type: types.LOGOUT,
                    payload: {
                        ...data
                    }
                });
                window.location.reload();
            }).catch(({ response }) => {
                const { data, ...rest } = response.data;
                dispatch(Update({ ...rest }));
            }).finally(() => {
                dispatch(actions.app.loading(false));
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
