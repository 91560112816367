import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Style/index.scss';
import { menuItems } from '../MenuItems';
import { url } from '../../../';

export function SideMenuLayout(props) {
    const location = useLocation();

    const { reminderColumn, user } = props;

    let path = location.pathname;

    useEffect(() => {
        const allowedPaths = [
            '/profile',
            '/dj-mix',
            '/albums',
            '/beatz',
            '/music',
            '/events',
            '/feeds',
            '/videos',
            '/movies',
            '/followers',
            '/chat'
        ];

        if (allowedPaths.includes(path)) {
            document.querySelectorAll('.side-menu li a').forEach((link) => {
                link.classList.remove('active');
            });

            let targetElement = document.querySelector(`.side-menu li a[href='${path}']`);
            targetElement.classList.add('active');
        }
    }, [path]);

    let avatar = user.image
        ? `${url}/storage/avatars/${user.image}`
        : require('../../../Images/avatar.png').default;

    return (
        <section className="container-fluid">
            <div className="row">
                {/* Side menu items */}
                <div className="col-md-2 side-menu-section">
                    <ul className="side-menu">
                        <li>
                            <Link to={'/profile'}>
                                <div
                                    className={'user-avatar'}
                                    style={{
                                        backgroundImage: `url(${avatar})`
                                    }}
                                ></div>

                                {user.username}
                            </Link>
                        </li>
                        {menuItems.map((item, index) =>
                            !item.hasOwnProperty('admin') ? (
                                <li key={index}>
                                    <Link to={item.link}>
                                        <img
                                            src={require(`./Images/${item.icon}`).default}
                                            alt="item"
                                        />
                                        {item.name}
                                    </Link>
                                </li>
                            ) : user.admin ? (
                                <li key={index}>
                                    <Link to={item.link}>
                                        <img
                                            src={require(`./Images/${item.icon}`).default}
                                            alt="icon"
                                        />
                                        {item.name}
                                    </Link>
                                </li>
                            ) : (
                                ''
                            )
                        )}
                    </ul>
                </div>

                {/* Main page content */}
                <div className='col-md-10 mobile-full-width'>
                    <div className="subsection-content">{props.children}</div>
                </div>
            </div>
        </section>
    );
}
