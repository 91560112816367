import { Countries } from '../../../';

export function setVideoFields(video) {
    return [
        {
            name: 'video',
            type: 'file',
            label: 'Video File',
            displayType: 'full',
            autofocus: true,
            required: false
        },
        {
            name: 'thumbnail',
            type: 'file',
            displayType: 'full',
            label: 'Video thumbnail',
            placeholder: 'Video thumbnail',
            autofocus: false
        },
        {
            name: 'title',
            type: 'text',
            displayType: 'half',
            label: 'Video title',
            placeholder: 'Video title',
            value: video ? video.title : '',
            autofocus: false,
            required: true
        },
        {
            name: 'artist',
            type: 'text',
            displayType: 'half',
            label: 'Video artist',
            placeholder: 'Video artist',
            autofocus: false,
            value: video ? video.artist : ''
        },
        {
            name: 'description',
            type: 'textarea',
            placeholder: 'Write a short description of the video',
            label: 'Video Description',
            displayType: 'full',
            autofocus: false,
            value: video ? video.description : ''
        },
        {
            name: 'country',
            type: 'select',
            placeholder: 'Country',
            label: 'Video Origin',
            autofocus: false,
            displayType: 'full',
            options: () => Countries.map(c => ({
                id: c,
                name: c
            })),
            required: true,
            value: video ? video.country : ''
        },
        {
            name: '',
            type: 'button',
            placeholder: 'Upload',
            autofocus: false,
            displayType: 'full'
        }
    ];
}
