import * as types from './constants';

export const Update = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.ERROR_UPDATE,
            payload: data
        });
    };
};

export const Clear = () => {
    return (dispatch) => {
        dispatch({
            type: types.ERROR_CLEAR,
            payload: {}
        });
    };
};
