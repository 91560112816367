import React, { useState, useRef } from 'react';
import { VideoCard } from '../VideoCard';
import './Style/index.scss';
import { SearchBox, TabSwitcher, ModalOverlay, ViewResource, UploadModal } from '../../../';
import { Spinner } from '../../Spinner';
import { setVideoFields } from './videoFields';

export function VideoTemplate({ videos, isLoading, type, user, uploadFormFields, reload }) {
    const [currentVideo, setCurrentVideo] = useState(videos[0]);

    const [updateVideoFields, setUpdateVideoFields] = useState([]);

    const [id, setId] = useState(0);

    const modalRef = useRef();

    const updateModalRef = useRef();

    const updateVideo = (video) => {
        let fields = setVideoFields(video);

        setId((prevState) => (prevState === video.id ? 0 : video.id));

        setUpdateVideoFields(fields);

        updateModalRef.current.open();
    };

    const viewVideo = (video) => {
        setCurrentVideo(video);

        modalRef.current.open();
    };

    return (
        <>
            <SearchBox
                reload={reload}
                user={user}
                searchText={`Search for ${type}`}
                type={type}
                uploadFormTitle={`${type} upload`}
                uploadFormFields={uploadFormFields}
            />

            <ModalOverlay ref={modalRef}>
                <ViewResource type={type} mediaType={type} post={currentVideo} />
            </ModalOverlay>

            <ModalOverlay ref={updateModalRef}>
                <UploadModal
                    type={type}
                    key={id}
                    id={id}
                    title={'Edit Video'}
                    editResource={true}
                    fields={updateVideoFields}
                />
            </ModalOverlay>

            <TabSwitcher
                tabLabel1={`All ${type}`}
                tabLabel2={`New ${type}`}
                content1={() =>
                    isLoading ? (
                        <Spinner />
                    ) : videos.length > 0 ? (
                        <ul className="video-list">
                            {videos.map((video, index) => (
                                <VideoCard
                                    viewVideo={viewVideo}
                                    context={'video'}
                                    updateVideo={updateVideo}
                                    type={type}
                                    video={video}
                                    key={index}
                                />
                            ))}
                        </ul>
                    ) : (
                        <p className="no-content">No {type}</p>
                    )
                }
                content2={() =>
                    isLoading ? (
                        <Spinner />
                    ) : videos.length > 0 ? (
                        <ul className="video-list">
                            {videos.map((video, index) => (
                                <VideoCard
                                    viewVideo={viewVideo}
                                    context={'video'}
                                    updateVideo={updateVideo}
                                    type={type}
                                    video={video}
                                    key={index}
                                />
                            ))}
                        </ul>
                    ) : (
                        <p className="no-content">No {type}</p>
                    )
                }
            />
        </>
    );
}
