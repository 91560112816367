import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Style/index.scss';
import { SideMenuLayout } from '../SideMenu/';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { actions } from '../../../../Store';
import { Token, url, SettingsDropdown, MusicPlayerContext, MusicPlayer } from '../../../';

export function UniversalLayout({ children, showSideMenu, reminderColumn, user }) {
    let dispatch = useDispatch();

    let location = useLocation();

    let history = useHistory();

    // const pathWithAudioFiles = ['/music', 'dj-mix', 'beatz', 'feeds', '/'];

    const audio = useRef('audio_tag');
    const playlistRef = useRef('playlist');
    const playlistCopyRef = useRef('playlistCopy');
    // const playerUiRef = useRef('player');
    const volumeRef = useRef('volume-tracker');

    let [showSettings, setShowSettings] = useState(false);
    let [showNotification, setShowNotification] = useState(false);
    let [showChat, setShowChat] = useState(false);

    // let [defaultSongs, setDefaultSongs] = useState([]);
    let [playing, setPlayState] = useState(0);
    let [bufferedLength, setBufferedLength] = useState(0);
    let [currentTimeLength, setCurrentTimeLength] = useState(0);
    let [playedTime, setPlayedTime] = useState('0:00');
    let [audioLength, setAudioLength] = useState('0:00');
    let [title, setTitle] = useState('Title');
    let [artist, setArtist] = useState('Artist');
    let [songId, setSongId] = useState(0);
    let [cover, setCover] = useState(require('./Images/default-music.png').default);
    let [playingAlbum, setPlayingAlbum] = useState(false);

    const { playlistObj } = useSelector((state) => ({
        playlistObj: state.playlist
    }));

    const { songs, isLoading } = playlistObj;

    useEffect(() => {
        if (user !== null && Object.keys(user).length !== 0 && Object.keys(songs).length !== 0) {
            loadDefaultPlaylist();
        }
    }, [user, songs]);

    // Load songs from default playlist
    useEffect(() => {
        if (user !== null && Object.keys(user).length !== 0) {
            dispatch(actions.playlist.GetPlaylist(Token));
        }
    }, [user, dispatch]);

    useEffect(() => {
        if (user && Object.keys(user).length !== 0) {
            audio.current.addEventListener('timeupdate', function () {
                updateBufferedLength();
                updateCurrentTimeLength();
                updateTime();
            });

            audio.current.addEventListener('loadedmetadata', function () {
                updateTime();
                updateAudioLength();
            });

            volumeRef.current.addEventListener('click', function (e) {
                volumeRef.current.querySelector('.tracker').style.width =
                    (e.layerX / 98) * 100 + '%';
                audio.current.volume = e.layerX / 98;
            });

            audio.current.addEventListener('ended', function () {
                next();
            });
        }
    }, [user]);

    const toggleDropdown = (type) => {
        setShowSettings(false);
        setShowNotification(false);
        setShowChat(false);

        switch (type) {
            case 'settings':
                setShowSettings(!showSettings);
                break;
            case 'notification':
                setShowNotification(!showNotification);
                break;
            case 'chat':
                setShowChat(!showChat);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const unlisten = history.listen(() => {
            console.log('changing to....');
            setShowSettings(false);

            const sideMenu = document.querySelector('.side-menu-section');

            if (sideMenu) sideMenu.classList.remove('active');
        });

        return () => {
            unlisten();
        };
    }, []);

    const loadDefaultPlaylist = () => {
        if (songs.length > 0) {
            const firstSong = playlistCopyRef.current.querySelector('tr:first-child');

            playSong(firstSong, firstSong, false);
        }
    };

    const updateBufferedLength = () => {
        audio.current.onprogress = () => {
            if (audio.current.target === 4) {
                let buffered = audio.current.buffered.end(0) / audio.current.duration;
                setBufferedLength(buffered * 100 + '%');
            }
        };
    };

    const updateCurrentTimeLength = () => {
        let currentTime = audio.current.currentTime / audio.current.duration;
        setCurrentTimeLength(currentTime * 100 + '%');
    };

    const updateTime = () => {
        let duration = (audio.current.currentTime / 100).toFixed(2).replace('.', ':');
        setPlayedTime(duration);
    };

    const updateAudioLength = () => {
        let duration = (audio.current.duration / 100).toFixed(2).replace('.', ':');
        setAudioLength(duration);
    };

    const next = () => {
        let currentMusic = null;

        let virtualCurrent = playlistCopyRef.current.querySelector('.active');

        if (!playingAlbum) {
            currentMusic = playlistCopyRef.current.querySelector('.active');
        } else {
            currentMusic = playlistRef.current.querySelector('.active');
        }

        if (currentMusic.nextSibling) {
            let next = currentMusic.nextSibling;
            let virtualNext = virtualCurrent.nextSibling;
            playSong(next, virtualNext);
        } else {
            // setPlayState(0);
        }
    };

    const playAlbum = (e) => {
        setPlayingAlbum(true);

        playlistCopyRef.current.querySelectorAll('tr').forEach((r) => {
            r.remove();
        });

        console.log(playlistCopyRef.current);

        const firstSong = playlistRef.current.querySelector('tr:first-child');

        let allSongs = playlistRef.current.querySelectorAll('tr');

        if (allSongs.length === 0) {
            return;
        }

        allSongs.forEach((song) => {
            let cln = song.cloneNode(true);
            playlistCopyRef.current.append(cln);
        });

        const firstSong2 = playlistCopyRef.current.querySelector('tr:first-child');

        playSong(firstSong, firstSong2, true, true);
    };

    const prev = () => {
        let currentMusic = null;

        let virtualCurrent = playlistCopyRef.current.querySelector('.active');

        if (!playingAlbum) {
            currentMusic = playlistCopyRef.current.querySelector('.active');
        } else {
            currentMusic = playlistRef.current.querySelector('.active');
        }

        if (currentMusic.previousSibling) {
            let previous = currentMusic.previousSibling;
            let virtualPrevious = virtualCurrent.previousSibling;
            playSong(previous, virtualPrevious);
        }
    };

    const playSong = (song, song2, play = true, album = false) => {
        if (album || playingAlbum) {
            let allSongs = playlistRef.current.querySelectorAll('tr');

            allSongs.forEach((s) => {
                s.classList.remove('active');
            });

            song.classList.add('active');
        }

        let allSongs2 = playlistCopyRef.current.querySelectorAll('tr');

        allSongs2.forEach((s2) => {
            s2.classList.remove('active');
        });

        song2.classList.add('active');

        let src = song2.getAttribute('data-src');
        let cover = song2.getAttribute('data-cover');
        let title = song2.getAttribute('data-title');
        let artist = song2.getAttribute('data-artist');
        let id = song2.getAttribute('data-id');

        setSongId(id);
        setTitle(title);
        setCover(cover);
        setArtist(artist);

        audio.current.src = src;

        if (id) {
            // addView(id);
        }

        if (play) {
            playAudio();

            setPlayState(1);
        }
    };

    const repeat = () => {
        audio.current.currentTime = 0;
        playAudio();
        setPlayState(1);
    };

    const playAudio = () => {
        var playPromise = audio.current.play();

        if (playPromise !== undefined) {
            playPromise.then((_) => {}).catch((error) => {});
        }
    };

    const handleSongClick = (e) => {
        let element = e.target.parentElement;

        let row = element.tagName === 'TD' ? element.parentElement : element;

        setPlayingAlbum(true);

        playlistCopyRef.current.querySelectorAll('tr').forEach((r) => {
            r.remove();
        });

        let allSongs = playlistRef.current.querySelectorAll('tr');

        allSongs.forEach((song) => {
            let cln = song.cloneNode(true);
            playlistCopyRef.current.append(cln);
        });

        let row2 = playlistCopyRef.current.querySelector(
            `tr[data-id='${row.getAttribute('data-id')}']`
        );

        playSong(row, row2, true, true);
    };

    const toggleSideMenu = () => {
        const sideMenu = document.querySelector('.side-menu-section');
        sideMenu.classList.toggle('active');
    };

    const play = (e) => {
        if (!audio.current.src) return;

        if (audio.current.paused) {
            playAudio();
            setPlayState(1);
        } else {
            audio.current.pause();
            setPlayState(0);
        }
    };

    return user !== null && Object.keys(user).length !== 0 ? (
        <MusicPlayerContext.Provider
            value={{
                playlist: {},
                clickSong: handleSongClick,
                playAlbum: playAlbum,
                play: play,
                playListRef: playlistRef,
                songId: songId
            }}>
            <main className="container app-layout">
                <section className="nav-area">
                    {/* Navigator for mobile devices */}
                    {(location.pathname === '/' || location.pathname === '/feeds') && (
                        <div className="mobile-nav">
                            <ul className="mobile-menu-list">
                                <li className="list-box logo-link">
                                    <Link to={'/'}>
                                        <img
                                            src={require('../../../Images/Logo.png').default}
                                            alt="logo"
                                        />
                                    </Link>
                                </li>
                                <li className="list-box msg-link">
                                    <Link to={'/chat'}>
                                        <img
                                            src={require('./Images/Mobile/message.svg').default}
                                            alt="msg"
                                        />
                                    </Link>
                                </li>
                                <li className="list-box notification-link">
                                    <img
                                        src={require('./Images/Mobile/notification.svg').default}
                                        alt="notification"
                                    />
                                </li>
                                <li className="list-box settings-link" onClick={() => toggleDropdown('settings')}>
                                    <img
                                        src={require('./Images/Mobile/settings.svg').default}
                                        alt="settings"
                                    />
                                    <SettingsDropdown url={url} active={showSettings} user={user} />
                                </li>
                                <li className="list-box search-link">
                                    <img
                                        src={require('./Images/Mobile/search.svg').default}
                                        alt="search"
                                    />
                                </li>
                                <li className="list-box hamburger-link" onClick={toggleSideMenu}>
                                    <img
                                        src={require('./Images/Mobile/hamburger.svg').default}
                                        alt="hambuger"
                                    />
                                </li>
                            </ul>
                        </div>
                    )}

                    <nav className="mobile-go">
                        <Link to={'/'} className="logo-area">
                            <img src={require('../../../Images/Logo.png').default} alt="logo" />
                        </Link>

                        <div className="search-area">
                            <form>
                                <input type="search" placeholder="Search" />
                            </form>
                        </div>

                        <audio ref={audio} preload={'auto'} />

                        <MusicPlayer
                            cover={cover}
                            title={title}
                            artist={artist}
                            repeat={repeat}
                            prev={prev}
                            next={next}
                            play={play}
                            playing={playing}
                            playedTime={playedTime}
                            currentTimeLength={currentTimeLength}
                            audioLength={audioLength}
                            volumeRef={volumeRef}
                        />

                        <div className="notification-area">
                        <Link to={'/chat'}>
                            <img
                                src={require('./Images//msg-notif.png').default}
                                alt="message icon"
                            />
                        </Link>

                            <span>
                            <img src={require('./Images/bell-notif.png').default} alt="bell icon" />
                        </span>

                            <span>
                            <img
                                src={require('./Images/caret-notif.png').default}
                                onClick={() => toggleDropdown('settings')}
                                alt="caret icon"
                            />
                        </span>

                            {/* Dropdown for settings */}
                            <SettingsDropdown url={url} active={showSettings} user={user} />
                        </div>
                    </nav>
                </section>

                <section className="page-content">
                    {showSideMenu ? (
                        <SideMenuLayout user={user} reminderColumn={reminderColumn}>
                            {children}
                        </SideMenuLayout>
                    ) : (
                        children
                    )}

                    <div className="mobile-bottom-space mobile-show"></div>
                </section>

                {/* bottom navigation   */}
                <div className={'bottom-navigator'}>
                    <div className="mobile-music-player">
                        <MusicPlayer
                            cover={cover}
                            title={title}
                            artist={artist}
                            repeat={repeat}
                            prev={prev}
                            next={next}
                            play={play}
                            playing={playing}
                            playedTime={playedTime}
                            currentTimeLength={currentTimeLength}
                            audioLength={audioLength}
                            volumeRef={volumeRef}
                        />
                    </div>
                </div>

                <table>
                    {/* Virtual playlist, an invisible playlist that has a
                        predefined list of songs by default, and its updated when a user clicks play on an album or songs, this is to keep track of the
                        list of songs as we navigate thru the website, bcus this particular component is a parent component of the entire website */}
                    <tbody className="virtual-playlist" ref={playlistCopyRef}>
                    {songs.length > 0 && (
                        songs.map((song, index) => (
                            <tr
                                data-title={song.title}
                                data-artist={song.artist_name}
                                data-src={`${url}/storage/musicAudio/${song.music_path}`}
                                data-cover={
                                    song.image_path !== 'undefined'
                                        ? `${url}/storage/musicCover/${song.image_path}`
                                        : require('./Images/default-music.png').default
                                }
                                key={index}
                                className={'song-row'}></tr>
                        ))
                    )}
                    </tbody>
                </table>
            </main>
        </MusicPlayerContext.Provider>
    ) : (
        children
    );
}
