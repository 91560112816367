import React from 'react';
import './Style/index.scss';

export function MobileSearh({ placeholder }) {
    return (
        <form className="mobile-search-form">
            <input type="search" placeholder={placeholder} name="keyword" />
        </form>
    );
}
