import React from 'react';
import { useDispatch } from 'react-redux';
import './Style/index.scss';
import { Token, actions } from '../../';

export function PostActions({ likeId, userId }) {
    const dispatch = useDispatch();

    const likePost = (e) => {
        e.preventDefault();
        dispatch(actions.news.LikeNews(e.target.elements, Token));
    };

    return (
        <div className="post-actions">
            <div className="resource-actions">
                <div className="action-box">
                    <form onSubmit={likePost}>
                        <input type="hidden" name="like_type" value="news" />
                        <input type="hidden" name="like_id" value={likeId} />
                        <input type="hidden" name="user_id" value={userId} />
                        <button>
                            <img src={require('./Images/like.png').default} alt="like" />
                        </button>
                    </form>

                    <p>Like</p>
                </div>
                {/* <div className='action-box'>
                    <img src={require('./Images/comment.png').default} /> 
                    <p className="text-fix">Comment</p>
                </div> */}
                <div className="action-box">
                    <img src={require('./Images/share.png').default} alt="share" />
                    <p>Share</p>
                </div>
            </div>
        </div>
    );
}
