import * as types from './constants.js';
import { GetRequest } from '../../../Api';

export const CreateArtist = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_ARTIST,
            payload: data
        });
    };
};

export const GetArtists = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'artist');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_ARTISTS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const GetAlbum = (token, id) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'artist/' + id);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_ARTIST,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
