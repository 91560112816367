import { handleActions } from 'redux-actions';
import * as types from './constants';

export let NotificationState = '';

export default handleActions(
    {
        [types.SET_MESSAGE]: (state = NotificationState, action) => {
            return action.payload;
        }
    },
    NotificationState
);
