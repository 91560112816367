const Countries = [
    'Nigeria',
    'Ethiopia',
    'Egypt',
    'DR Congo',
    'Tanzania',
    'South Africa',
    'Kenya',
    'Uganda',
    'Algeria',
    'Sudan',
    'Morocco',
    'Angola',
    'Mozambique',
    'Ghana',
    'Madagascar',
    'Cameroon',
    "Côte d'Ivoire",
    'Niger',
    'Burkina Faso',
    'Mali',
    'Malawi',
    'Zambia',
    'Senegal',
    'Chad',
    'Somalia',
    'Zimbabwe',
    'Guinea',
    'Rwanda',
    'Benin',
    'Burundi',
    'Tunisia',
    'Togo',
    'South Sudan',
    'Sierra Leone',
    'Libya',
    'Congo',
    'Liberia',
    'Central African Republic',
    'Mauritania',
    'Eritea',
    'Gambia',
    'Namibia',
    'Botswana',
    'Gabon',
    'Lesotho',
    'Guinea-Bissau',
    'Equatorial Guinea',
    'Mauritius',
    'Eswatini',
    'Djibouti',
    'Comoros',
    'Cabo Verde',
    'Sao Tome & Principe',
    'Seychelles'
];

export { Countries };
