import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { createStore as Store } from './Store';
import './Shared/Styling/general.scss';
// import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import '../node_modules/font-awesome/scss/font-awesome.scss';

ReactDOM.render(
    <Provider store={Store}>
        <Router>
            <Routes />
        </Router>
    </Provider>,
    document.getElementById('root')
);
