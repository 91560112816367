import { Countries } from '../..';

export const VideoUploadFields = [
    {
        name: 'video',
        type: 'file',
        label: 'Video File',
        displayType: 'full',
        autofocus: true,
        required: true
    },
    {
        name: 'thumbnail',
        type: 'file',
        displayType: 'full',
        label: 'Video thumbnail',
        placeholder: 'Video thumbnail',
        autofocus: false
    },
    {
        name: 'title',
        type: 'text',
        displayType: 'half',
        label: 'Video title',
        placeholder: 'Video title',
        autofocus: false,
        required: true
    },
    {
        name: 'artist',
        type: 'text',
        displayType: 'half',
        label: 'Video artist',
        placeholder: 'Video artist',
        autofocus: false
    },
    {
        name: 'description',
        type: 'textarea',
        placeholder: 'Write a short description of the video',
        label: 'Video Description',
        displayType: 'full',
        autofocus: false
    },
    {
        name: 'country',
        type: 'select',
        placeholder: 'Country',
        label: 'Video Origin',
        autofocus: false,
        displayType: 'full',
        options: () => Countries.map(c => ({
            id: c,
            name: c
        })),
        required: true
    },
    {
        name: '',
        type: 'button',
        placeholder: 'Upload',
        autofocus: false,
        displayType: 'full'
    }
];
