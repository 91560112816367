import { handleActions } from 'redux-actions';
import * as types from './constants';

export let FollowState = {
    isLoading: false,
    followers: [],
    following: [],
    requests: [],
    isFollowing: false
};

export default handleActions(
    {
        [types.CREATE_FOLLOWER]: (state = FollowState, action) => {
            state = {
                isLoading: false,
                followers: [...action.payload.followers],
                following: [...action.payload.following],
                requests: [...action.payload.requests],
                isFollowing: false
            };

            return state;
        },

        [types.LOAD_FOLLOWERS]: (state = FollowState, action) => {
            state = {
                isLoading: state.isLoading,
                followers: [...action.payload.followers],
                following: [...action.payload.following],
                requests: [...action.payload.requests],
                isFollowing: state.isFollowing
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = FollowState, action) => {
            state = {
                isLoading: action.payload,
                followers: state.followers,
                following: state.following,
                requests: state.requests,
                isFollowing: state.isFollowing
            };

            return state;
        }
    },
    FollowState
);
