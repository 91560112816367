import React from 'react';
import './Style/index.scss';

export function TabSwitcher(props) {
    const { tabLabel1, tabLabel2, tabLabel3, totalTabs, content1, content2, content3 } = props;

    const toggleTab = (e) => {
        const el = e.target;
        const target = document.querySelector(e.target.getAttribute('data-target'));
        document.querySelectorAll('.tab-content').forEach((item) => {
            item.classList.remove('active');
        });
        document.querySelectorAll('.toggler').forEach((item) => {
            item.classList.remove('active');
        });
        el.classList.toggle('active');
        target.classList.toggle('active');
    };

    return (
        <div className="main-content">
            <ul className="tab-toggler">
                <li className="toggler active" onClick={toggleTab} data-target=".tab1">
                    {tabLabel1}
                </li>
                <li className="toggler" onClick={toggleTab} data-target=".tab2">
                    {tabLabel2}
                </li>
                {
                    totalTabs === 3 && (
                        <li className="toggler" onClick={toggleTab} data-target=".tab3">
                            {tabLabel3}
                        </li>
                    )
                }
            </ul>

            <div className="tab-content tab1 active">{content1()}</div>

            <div className="tab-content tab2">{totalTabs !== 1 && content2()}</div>

            { totalTabs === 3 && <div className="tab-content tab3">{content3()}</div> }
        </div>
    );
}
