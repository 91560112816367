import { handleActions } from 'redux-actions';
import * as types from './constants';

export let PlaylistState = {
    isLoading: false,
    songs: []
};

export default handleActions(
    {
        [types.CREATE_MUSIC]: (state = PlaylistState, action) => {
            let newSongs = state.songs;
            newSongs.unshift(action.payload);
            state = {
                isLoading: state.isLoading,
                songs: [...newSongs]
            };
            return state;
        },

        [types.LOAD_PLAYLIST]: (state = PlaylistState, action) => {
            state = {
                isLoading: state.isLoading,
                songs: [...action.payload]
            };

            return state;
        },

        [types.LOADING_STATUS]: (state = PlaylistState, action) => {
            state = {
                isLoading: action.payload,
                songs: state.songs
            };
            return state;
        }
    },
    PlaylistState
);
