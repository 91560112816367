import * as types from './constants.js';
import { actions } from '../';
import { PostRequest, GetRequest } from '../../../Api';

export const CreateNews = (form, token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, 'news', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.CREATE_NEWS,
                    payload: data
                });
                dispatch(Loading(false));
                dispatch(actions.notification.SetMessage('News added!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};


export const UpdateNews = (form, id, token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        const result = PostRequest(form, `news/${id}`, token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.UPDATE_GENERAL_NEWS,
                    payload: data
                });
                dispatch(Loading(false));
                dispatch(actions.notification.SetMessage('News updated!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const LikeNews = (form, token) => {
    return (dispatch) => {
        const result = PostRequest(form, 'like', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LIKE_NEWS,
                    payload: data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const AddComment = (form, token) => {
    return (dispatch) => {
        const result = PostRequest(form, 'comments', token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.NEWS_ADD_COMMENT,
                    payload: data
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const LoadPersonalNews = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'news');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_PERSONAL_NEWS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const LoadGeneralNews = (token) => {
    return (dispatch) => {
        dispatch(Loading(true));

        let result = GetRequest({}, token, 'all-news');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_GENERAL_NEWS,
                    payload: data
                });
                dispatch(Loading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(Loading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
