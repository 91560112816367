import { lazy } from 'react';

//Load Components only when they are needed, improves initial load speed
export const Login = lazy(() => import('../Modules/Auth').then((module) => ({ default: module.Login })));

export const Settings = lazy(() =>
    import('../Modules/Settings').then((module) => ({ default: module.Settings }))
);

export const Followers = lazy(() =>
    import('../Modules/Followers').then((module) => ({ default: module.Followers }))
);

export const Movies = lazy(() =>
    import('../Modules/Movies').then((module) => ({ default: module.Movies }))
);

export const Chat = lazy(() => import('../Modules/Chat').then((module) => ({ default: module.Chat })));

export const Videos = lazy(() =>
    import('../Modules/Videos').then((module) => ({ default: module.Videos }))
);

export const Register = lazy(() =>
    import('../Modules/Auth').then((module) => ({ default: module.Register }))
);

export const ForgotPassword = lazy(() =>
    import('../Modules/Auth').then((module) => ({ default: module.ForgotPassword }))
);

export const PasswordReset = lazy(() =>
    import('../Modules/Auth').then((module) => ({ default: module.PasswordReset }))
);

export const UserProfile = lazy(() =>
    import('../Modules/Profile').then((module) => ({ default: module.UserProfile }))
);

export const OtherUserProfile = lazy(() =>
    import('../Modules/Profile').then((module) => ({ default: module.OtherUserProfile }))
);

export const News = lazy(() => import('../Modules/Feeds').then((module) => ({ default: module.News })));

export const Albums = lazy(() =>
    import('../Modules/Albums').then((module) => ({ default: module.Albums }))
);

export const AlbumView = lazy(() =>
    import('../Modules/Albums').then((module) => ({ default: module.AlbumView }))
);

export const Artists = lazy(() =>
    import('../Modules/Artists').then((module) => ({ default: module.Artists }))
);

export const AuthLayout = lazy(() =>
    import('../Modules/Auth').then((module) => ({ default: module.AuthLayout }))
);

export const Mp3 = lazy(() => import('../Modules/Mp3').then((module) => ({ default: module.Mp3 })));

export const Beatz = lazy(() => import('../Modules/Beatz').then((module) => ({ default: module.Beatz })));

export const Djmix = lazy(() => import('../Modules/Djmix').then((module) => ({ default: module.Djmix })));

export const EventPage = lazy(() =>
    import('../Modules/Event').then((module) => ({ default: module.EventPage }))
);

export const EventView = lazy(() =>
    import('../Modules/Event').then((module) => ({ default: module.EventView }))
);

export const Error404 = lazy(() =>
    import('../Modules/Errors').then( (module) => ({ default: module.Error404 }))
);