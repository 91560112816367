//General api settings here..

import { PostRequest } from './PostRequests';
import { GetRequest } from './GetRequests';
import { DeleteRequest } from './DeleteRequest';

// const url = "http://localhost:8000";

// const api = "http://localhost:8000/v1/";

// const chatServer = "localhost:6001";

const url = 'https://api.africaplusworld.com';

const api = 'https://api.africaplusworld.com/v1/';

const chatServer = 'https://api.africaplusworld.com:6001';

export default url;

export { url, api, chatServer, PostRequest, GetRequest, DeleteRequest };
