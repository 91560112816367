import * as types from './constants.js';

export const SetMessage = (msg) => {
    return (dispatch) => {
        dispatch({
            type: types.SET_MESSAGE,
            payload: msg
        });
    };
};
