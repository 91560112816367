import * as types from './constants.js';
import { actions } from '../';
import { Loading as postLoading } from '../Posts/actions';
import { GetRequest, DeleteRequest } from '../../../Api';

export const CreatePost = (data) => {
    return (dispatch) => {
        dispatch({
            type: types.CREATE_POST,
            payload: data
        });
    };
};

export const DeletePost = (id, token) => {
    return (dispatch) => {
        const result = DeleteRequest(`posts/${id}`, token);

        result
            .then(({ data }) => {
                dispatch({
                    type: types.DELETE_POST,
                    payload: data
                });
                dispatch(postLoading(false));
                dispatch(actions.notification.SetMessage('Post Deleted!'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(postLoading(false));
            });
    };
};

export const GetPosts = (token) => {
    return (dispatch) => {
        dispatch(postLoading(true));

        let result = GetRequest({}, token, 'posts');

        result
            .then(({ data }) => {
                dispatch({
                    type: types.LOAD_POSTS,
                    payload: data
                });
                dispatch(postLoading(false));
            })
            .catch((error) => {
                console.log(error);
                dispatch(postLoading(false));
            });
    };
};

export const Loading = (isLoading) => {
    return (dispatch) => {
        dispatch({
            type: types.LOADING_STATUS,
            payload: isLoading
        });
    };
};
